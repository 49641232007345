
import { defineComponent } from "vue-demi";
import Tariff from "@/components/owner/tariff/index.vue";

export default defineComponent({
  name: "OwnerTariffView",
  components: {
    Tariff,
  },
});
